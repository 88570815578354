<template>
  <div>
    <label v-if="label" class="select-label">{{ label }}</label>
    <div class="select-container">
      <input
        class="select-type"
        :class="{ 'form-control': true, disabled }"
        :style="cssVars"
        :disabled="disabled"
        @click="changeOptionsVisibility"
        v-on:blur="closeOptions"
      />
      <div
        v-if="selected?.label && !selected?.placeholder"
        class="select-value"
        :style="cssVars"
      >
        {{ selected.label }}
      </div>
      <div v-else class="placeholder">{{ placeholder }}</div>
      <span class="icon-box" v-if="!hideChevron">
        <ChevronDown
          :class="{
            icon: true,
            stroke: true,
            'select-arrow': true,
            'opened-options': showOptions
          }"
        />
      </span>
      <div v-if="showOptions" class="select-option-container" :style="cssVars">
        <div
          class="option"
          v-for="option in options"
          :key="option.label"
          @click="handleSelected(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SimpleSelect',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg')
  },
  props: {
    label: String,
    placeholder: String,
    disabled: Boolean,
    options: { type: Array, required: true },
    value: {
      validator: v => true
    },
    object: Object,
    border: { type: String, default: '1px solid var(--neutral-300)' },
    colorOptions: { type: String, default: 'var(--neutral-100)' },
    fontColor: { type: String, default: 'var(--type-active)' },
    backgroundColor: { type: String, default: 'var(--neutral-000)' },
    fontWeight: { type: String, default: '500' },
    borderColor: { type: String, default: 'var(--neutral-300)' },
    borderRadius: { type: String, default: '8px' },
    hideChevron: Boolean
  },
  data() {
    return {
      showOptions: false,
      selected: null
    }
  },
  computed: {
    cssVars() {
      return {
        '--border': this.border,
        '--color-options': this.colorOptions,
        '--background-color': this.backgroundColor,
        '--font-color': this.fontColor,
        '--font-weight': this.fontWeight,
        '--border-color': this.borderColor,
        '--border-radius': this.borderRadius
      }
    }
  },
  created() {
    this.selected = this.options.find(
      o => JSON.stringify(o.value) === JSON.stringify(this.value)
    )
  },
  watch: {
    value: function (val) {
      this.selected = this.options.find(
        o => JSON.stringify(o.value) === JSON.stringify(val)
      )
    },
    options: function () {
      this.selected = this.options.find(
        o => JSON.stringify(o.value) === JSON.stringify(this.value)
      )
    }
  },
  methods: {
    closeOptions() {
      setTimeout(() => {
        this.showOptions = false
      }, 200)
    },

    changeOptionsVisibility() {
      this.showOptions = !this.showOptions
    },

    handleSelected(option) {
      this.selected = option
      this.$emit('onChange', option.value, this.object)
      this.$emit('change', option.value)
      this.$emit('input', option.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.select-label {
  width: 100%;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  color: var(--type-active);
  text-align: left !important;
  color: var(--dark-blue);
  margin-bottom: 4px;
}
.select-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;

  .icon {
    transition: all 500ms;
  }

  .select-arrow {
    width: 25px;
    position: absolute;
    right: 10px;
    top: 8px;
    pointer-events: none;
  }
  .opened-options {
    transform: rotate(-180deg);
  }
  .select-type {
    -webkit-appearance: none;
    appearance: none;
    border: var(--border) !important;
    border-color: var(--border-color) !important;
    border-radius: var(--border-radius) !important;
    font-size: 16px;
    max-height: 38px;
    cursor: pointer;
    line-height: 1;
    outline: 0;
    background-color: var(--background-color);
    background-image: linear-gradient(var(--baseFg), var(--baseFg)),
      linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
      linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
      linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
    background-position: right 20px center, right bottom, right bottom,
      right bottom;
  }
  .placeholder {
    position: absolute;
    width: 89%;
    color: #8696ac;
    font-size: 14px;
    text-align: left;
    display: inline-flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 2rem 0 14px;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select-value {
    position: absolute;
    color: var(--font-color);
    font-weight: var(--font-weight);
    font-size: 16px;
    text-align: left;
    display: inline-flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 40px 0 20px;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select-option-container {
    position: absolute;
    max-height: 300px;
    background-color: var(--default-white) !important;
    width: 100%;
    top: 110%;
    left: 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-300);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    z-index: 200;
  }
  .option {
    padding: 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .option:hover {
    background-color: var(--color-options);
  }
  .placeholder-color {
    color: var(--type-placeholder);
  }
  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: var(--neutral-100);
    border-color: var(--neutral-300);
    color: var(--type-placeholder);
  }
}
</style>
